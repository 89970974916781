.console-header {
  @apply items-center px-16 h-24 relative overflow-hidden border-b bg-white;
  display: grid;
  grid-template-columns: 1fr auto ;
  grid-template-rows: 1fr;
}

.consoleHome {
  @apply grid grid-cols-[300px_1fr]  bg-neutral-200 overflow-auto h-[calc(100vh-96px)]  ;
  grid-template-rows: 1fr;
}

.console-sidebar {
  @apply bg-white pt-6 grid grid-rows-[1fr_auto] h-[calc(100vh-96px)] overflow-auto sticky top-0;
}

.console-sidebar_menu {
  @apply flex flex-col gap-2 font-fira text-sm;
}

.menu-title {
  @apply text-sm font-bold font-bpg text-neutral-500  pl-16 select-none;
}

.menu-list {
  @apply flex flex-col gap-1 text-neutral-600 mt-2 select-none;
}

.menu-item {
	@apply flex gap-2 items-center cursor-pointer hover:bg-black hover:text-white px-2 py-3 pl-16  text-sm font-fira;
}
.menu-item.active {
	@apply bg-black text-white;
}

.container-box {
	@apply rounded-md bg-white/80 px-2 py-2 w-full;
}

/* order-category */

.order-category {
  @apply flex gap-4 self-center bg-neutral-200 rounded-full border border-neutral-100 border-2 relative;
  position: relative;
}

.order-category-item {
  @apply rounded-full cursor-pointer py-3 px-4 font-fira text-sm relative;
  transition: background-color 1s ease; 
}

.order-category-item.active {
  @apply  text-white z-10;
}

.order-category-item::before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 98%;
  width: 100%;
  background-color: black; 
  border-radius: 9999px; 
  transition: transform 1s ease; 
  transform: scaleX(0); 
  transform-origin: center;
  z-index: -1;
}

.order-category-item.active:before {
  transform: scaleX(1.3); 
}