@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @media (max-height: 800px) {
      .h-800-max-h-400px {
        height: auto !important
      }
    }

    @media (max-height: 940px) {
        .h-950-max-auto {
          height: auto !important
        }
      }
  }

.welcomePage {
    display: grid;
    /* grid-template-columns: 1fr 0.5fr; */
    grid-template-rows: 1fr 300px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    grid-auto-flow: row;
    height: calc(100% - 9rem - 73px - 2rem);
    width: 100%;
    @apply mt-36 px-16 max-md:grid-cols-1 z-20 max-sm:grid-rows-[1fr_300px] sm:!grid-rows-[1fr_440px] ;
}


.welcomePageCover {
    @apply absolute right-0 top-0 -z-10 flex bg-[#111] -rotate-[20deg] ;
    width: 100%;
    height: 100%;
}    

.b-line {
    position: absolute;
    background-color: #111;
    @apply w-full h-full
}

.line-text::before {
    content: "60%";
    @apply text-4xl text-black font-bold;
}

.pageForm {
    @apply grid w-full;
    grid-template-rows: 1fr min-content;
}




.footer {
    @apply !bg-[#222222] !text-white p-5 grid px-20 max-md:!grid-cols-1 max-md:gap-10;
    grid-template-columns: 1fr 0.5fr;

}


.pageFormContent {
    @apply grid items-center justify-center pt-28 ;
    grid-template-rows: 0.1fr 1fr;
}

.subscriptionForm {
    @apply flex justify-center self-start p-10 max-w-[640px] justify-self-center w-full [&>*]:!font-bpg flex-col gap-3
}

.MuiFilledInput-root {
    @apply !bg-transparent border !rounded-lg border-white/30  !px-[10px];
    -webkit-transition : border 500ms ease-out !important;
    -moz-transition : border 500ms ease-out !important;
    -o-transition : border 500ms ease-out !important;
    transition : border 500ms ease-out !important;

}
.MuiFilledInput-underline > input {
    @apply !pl-3
}

.MuiFilledInput-root.Mui-focused {
    @apply border-white
}

.MuiFilledInput-root::before {
    @apply !border-none
}

.MuiFilledInput-root::after {
    @apply !border-none
}

.MuiInputLabel-root:not(.MuiInputLabel-shrink) {
    transform: translate(12px, 22px) scale(1);
}

.MuiInputLabel-root {
    @apply !pl-3
}

.MuiInputLabel-shrink {
    @apply !text-white/50
}

.MuiAutocomplete-popper{
    @apply [&>*]:!font-bpg
}

.MuiInputLabel-root {
    @apply !font-fira
}




/* PWW */
/* .part1 {
    --r: 50px;
    @apply absolute bg-[#D0D0D0] w-[100%] h-[90%] -z-10;
}

.part2 {
    @apply absolute bg-[#111] w-[100%] h-[110%] -z-10 rotate-[155deg] -right-[320px] -bottom-[500px]
} */
