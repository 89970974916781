@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./fonts.css");

body {
    @apply bg-[#D0D0D0] box-border;
}


/* D0D0D0 */


.header {
    @apply grid place-items-center mx-16 relative;
    grid-template-columns: 1fr 0;
}



/* TABLE */

.table-container-poor {
  @apply !border-none !shadow-none !bg-transparent
}

.table-poor > .head {
  @apply h-12  [&>*]:m-0 dark:!bg-transparent 
}

.table-poor > .head > tr > th {
  @apply   border-neutral-300 text-sm
}

.table-container > .head {
  @apply bg-neutral-100 border-neutral-300  !shadow-md h-12 [&>*]:border-none [&>*]:m-0 rounded-lg
}

.table-container > .head th {
  @apply  text-neutral-900 font-fira text-sm font-bold
}

.table-container > .footer {
  @apply dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-200 bg-neutral-300 border-neutral-300 text-neutral-900  border-none
}

.table-container .row {
  @apply  bg-neutral-100/80 border-neutral-300 text-neutral-900 min-h-12 h-12
}

.table-poor .row {
  @apply  !bg-transparent border-neutral-300 text-neutral-900 min-h-10 
}

.table-container .row > td {
  @apply border-r text-sm font-fira  !border-neutral-300 border-none
}

.table-poor .row > td {
  @apply  text-sm font-fira  !border-neutral-300 bg-neutral-200  h-12
}

.table-container .row > td:last-child{
  @apply border-r-0
}


.table-container .row:hover{
  @apply  [&>*]:bg-neutral-100/50   text-neutral-900 cursor-pointer
}

.table-container .row:focus {
  @apply [&>*]:bg-neutral-700 !text-neutral-900 dark:!text-neutral-200 dark:!bg-neutral-700
}

.box {
	@apply  bg-neutral-300/60 p-5 rounded-lg border border-neutral-300 dark:border-neutral-800 text-neutral-900 dark:text-neutral-300 sticky top-[85px] z-20  backdrop-blur-md shadow-sm
}

.tableFilter {
	@apply flex gap-5 flex-wrap items-end 
}
