@font-face {
    font-family: bpg;
    src: url('../assets/fonts/bpg_le_studio_02_caps.ttf') format('truetype');
}

@font-face {
    font-family: fira;
    src: url('../assets/fonts/FiraGO-Light.otf') format('truetype');
}

@font-face {
    font-family: Poppins;
    src: url('../assets/fonts/Poppins-Regular.ttf');
}

@font-face {
    font-family: sf;
    src: url('../assets/fonts/bpg_rioni.otf');
}

@font-face {
    font-family: 'gf';
    src: url('../assets/fonts/rrr.woff2') format('woff2');;
}

